<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <form @submit.prevent="saveSalepoint">
        <v-card :disabled="isLoading" :loading="isLoading">

          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>

          <v-card-text class="pa-8">

            <v-alert type="info" text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla efficitur venenatis nulla et porta.
            </v-alert>

            <v-text-field v-model="form.owner_phone"
                          :error-messages="errors.owner_phone"
                          label="Téléphone *"/>

          </v-card-text>

          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="isLoading"
                   color="primary"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      title: null,
      dialog: false,
      isLoading: false,
      errors: {},
      form: {},
    }
  },
  methods: {
    open(item) {
      this.title = !item ? "Ajouter un POS" : "Modifier un POS"
      this.dialog = true
      this.errors = {}
      this.form = {
        id: item ? item.id : null,
        images: item ? item.images : [],
        opening_hours: item ? item.opening_hours : {},
      }
    },
    close() {
      this.dialog = false
    },
    saveSalepoint() {
      this.errors = {}
      this.isLoading = true
      let url = !this.form.id ? '/v1/payment-salepoints/store' : '/v1/payment-salepoints/' + this.form.id + '/update'
      HTTP.post(url, this.form).then(() => {
        this.isLoading = false
        this.$emit('refresh')
        this.$successMessage = "Ce point de vente a été enregistré avec succès."

        if (this.form.id) {
          this.close()
        } else {
          this.form = {
            id: null,
            images: [],
            opening_hours: {},
          }
        }

      }).catch(err => {
        this.isLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger et réessayer."
        console.log(err)
      })
    },
  },
}
</script>

