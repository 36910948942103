<template>
  <div>
    <v-container fluid>

      <div class="main">

        <div class="text-end my-3">
          <v-btn class="rounded-lg"
                 color="gifty"
                 dark
                 depressed
                 @click="$refs.createDialog.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            Ajouter un POS
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                color="primary"
                indeterminate
                size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Recherche..."
                  rounded
                  single-line
              ></v-text-field>
            </div>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">
              <v-data-table v-if="salepoints.length"
                            :headers="headers"
                            :items="salepoints"
                            :search="keyword"
                            :loading="isLoading">


                <template v-slot:item.is_active="{ item }">
                  <v-switch color="success"
                            @click="changeStatus(item.id)"
                            class="pa-0 ma-0"
                            readonly
                            hide-details
                            v-model="item.is_active"></v-switch>
                </template>

                <template v-slot:item.phone="{ item }">
                   <span class="font-weight-medium gifty-text">
                     {{ item.salepoint.owner_phone }}
                   </span>
                </template>

                <template v-slot:item.map="{ item }">
                  <v-btn color="red"
                         icon
                         class="v-btn--active"
                         @click="openGoogleMaps(item.salepoint.latitude, item.salepoint.longitude)">
                    <v-icon>
                      mdi-map-marker
                    </v-icon>
                  </v-btn>
                </template>


                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center">
                    <v-btn icon>
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn icon @click="remove(item.id)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </div>
                </template>

              </v-data-table>

              <div v-else>
                <NoResults @reload="getSalepoints"/>
              </div>

            </v-card-text>
          </div>
        </v-card>

      </div>

    </v-container>

    <CreateDialog ref="createDialog" @refresh="getSalepoints"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import CreateDialog from "@/views/payment_salepoints/components/CreateDialog.vue";

export default {
  components: {
    CreateDialog,
    NoResults,
  },
  data() {
    return {
      id: '',
      keyword: '',
      overlay: false,
      isLoading: false,
      salepoints: [],
      headers: [
        {text: 'Statut', value: 'is_active'},
        {text: 'Nom', value: 'salepoint.pos_name'},
        {text: 'Téléphone', value: 'phone'},
        {text: 'Téléphone', value: 'salepoint.salepoint_type.name'},
        {text: 'Wilaya', value: 'salepoint.commune.name'},
        {text: 'Commune', value: 'salepoint.wilaya.name'},
        {text: 'Adresse', value: 'salepoint.adress'},
        {text: 'Map', value: 'map'},
        {text: 'Actions', value: 'actions', sortable: false},
      ]
    };
  },
  methods: {
    getSalepoints() {
      this.isLoading = true
      HTTP.get('/v1/payment-salepoints').then(res => {
        this.isLoading = false
        this.salepoints = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    openGoogleMaps(lat, lng) {
      const url = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(url, "_blank");
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.delete("/v1/payment-salepoints/" + this.id + "/delete").then(() => {
        this.overlay = false;
        let i = this.salepoints.findIndex((el) => el.id === this.id);
        this.$delete(this.salepoints, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    changeStatus(id) {
      this.overlay = true;
      HTTP.post("/v1/payment-salepoints/" + id + "/change-status").then(() => {
        this.overlay = false;
        let index = this.salepoints.findIndex((el) => el.id === id);
        this.salepoints[index].is_active = !this.salepoints[index].is_active
        this.$successMessage = "Cet élément a été modifié avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
  },
  created() {
    this.getSalepoints()
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem()
        this.$is_confirm = false
      }
    }
  }
};
</script>

<style scoped>
</style>
